import { reactive } from "vue";
import { User as FirebaseUser } from "firebase/auth";

type State = {
  loggedInUser: FirebaseUser | undefined;
  returnUrl: string | undefined;
  serviceWorkerState: ServiceWorkerState;
  firebaseMessagingToken: string | undefined;
};

type ServiceWorkerState = {
  refreshing: boolean;
  registration: ServiceWorkerRegistration | undefined;
  updateExists: boolean;
};

const initialState: State = {
  loggedInUser: undefined,
  returnUrl: undefined,
  firebaseMessagingToken: undefined,
  serviceWorkerState: {
    refreshing: false,
    updateExists: false,
    registration: undefined,
  },
};

export const initializeAfterLogin = async () => {
  return Promise.all([]);
};

export const Store = reactive<State>(initialState);

export const useStore = (): Readonly<State> => Store;
