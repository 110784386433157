import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "@/firebase";
import { Store } from "@/store";

const saveUserNotificationToken = async () => {
  const uid = Store.loggedInUser?.uid;
  const currentToken = Store.firebaseMessagingToken;
  if (!uid || !currentToken) {
    return;
  }

  const coll = collection(db, `users/${uid}/notificationTokens`);
  const q = query(coll, where("token", "==", currentToken));
  return getDocs(q).then((snapshot): void => {
    if (snapshot.docs.length === 0) {
      addDoc(coll, {
        userId: uid,
        token: currentToken,
        creationTs: new Date(Date.now()),
      });
    }
  });
};

export const DatabaseService = {
  saveUserNotificationToken,
};
