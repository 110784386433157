import "./registerServiceWorker";
import "@mdi/font/css/materialdesignicons.css";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import "vuetify/styles";
import { initializeAfterLogin, Store } from "@/store";
import { DatabaseService } from "@/services/DatabaseService";

const firebaseConfig = {
  apiKey: "AIzaSyDZt0QSRkHKlWl9Pn1PdeDSAn5R0Gkjw7g",
  authDomain: "deal-detector-d3928.firebaseapp.com",
  projectId: "deal-detector-d3928",
  storageBucket: "deal-detector-d3928.appspot.com",
  messagingSenderId: "783051896222",
  appId: "1:783051896222:web:37088f0467c02bf9b5928f",
  measurementId: "G-F8JLQ0EK77",
};

export const app = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();
const messaging = getMessaging(app);
onAuthStateChanged(auth, (user) => {
  if (user) {
    Store.loggedInUser = user;
    requestPermission().then((granted: boolean) => {
      if (!granted) return;
      if (!Store.firebaseMessagingToken) {
        _getToken();
      } else {
        DatabaseService.saveUserNotificationToken().catch((err) => {
          alert("Speichern des Notification Token fehlgeschlagen\n" + err);
        });
      }
    });
    initializeAfterLogin();
  } else {
    Store.loggedInUser = undefined;
  }
});

async function requestPermission(): Promise<boolean> {
  return Notification.requestPermission().then((permission) => {
    return permission === "granted";
  });
}

const _getToken = () => {
  getToken(messaging, {
    vapidKey:
      "BJHLZBsQ-t6AMCwZxlMqbQdz3mRSPhNsA0HguJNldeQHeWP_u1dx8fbFi4BTuNgwpeDPyaSbhyPBl5r3CxgYXvI",
  })
    .then((currentToken) => {
      if (currentToken) {
        Store.firebaseMessagingToken = currentToken;
        DatabaseService.saveUserNotificationToken();
      }
    })
    .catch(() => {});
};
_getToken();

export { db, auth, messaging };
