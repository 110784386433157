import { auth, messaging } from "@/firebase";
import { deleteToken } from "firebase/messaging";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

const signUpUser = async (email: string, password: string) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

const signInUser = async (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const signOutUser = async () => {
  // todo store reset
  deleteToken(messaging);
  return signOut(auth);
};

export const AuthService = {
  signUpUser,
  signInUser,
  signOutUser,
};
