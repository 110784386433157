<script lang="ts" setup>
import { computed, ref } from "vue";
import { Store, useStore } from "@/store";
import { AuthService } from "@/services/AuthService";
import { useRoute, useRouter } from "vue-router";
import { useDisplay } from "vuetify";
import { ServiceWorkerUpdateService } from "@/services/ServiceWorkerUpdateService";
import moment from "moment/moment";
import { onMessage } from "firebase/messaging";
import { messaging } from "@/firebase";

const drawerShown = ref(false);
const store = useStore();
const router = useRouter();
const route = useRoute();

const { mobile } = useDisplay();

moment.locale("de");

const paths = [
  { title: "Home", path: "/", icon: "mdi-home" },
  {
    title: "Profil",
    path: "/profile",
    icon: "mdi-pencil-outline",
  },
];

const isFullScreenRoute = computed(() => {
  return ["student"].filter((i) => route.name === i).length > 0;
});

const onLogout = async () => {
  AuthService.signOutUser()
    .then(() => {
      Store.returnUrl = "/";
      router.push("/login");
    })
    .catch((err) => {
      alert("Fehler beim Logout\n" + err);
    });
};

ServiceWorkerUpdateService.setupUpdateListeners();
const updateNotificationShown = ref(true);
const _updateNotificationShown = computed(() => {
  return store.serviceWorkerState.updateExists && updateNotificationShown.value;
});

const notificationDialogShown = ref(false);
const notification = ref<{ title: string; body: string }>();
onMessage(messaging, (payload) => {
  notificationDialogShown.value = true;

  notification.value = {
    title: payload.notification?.title || "",
    body: payload.notification?.body || "",
  };
});
</script>

<template>
  <v-app :class="[!isFullScreenRoute ? '' : 'detail-content-with-toolbar']">
    <v-app-bar id="main-app-bar" :elevation="2" color="primary">
      <v-app-bar-nav-icon
        v-if="store.loggedInUser"
        @click="drawerShown = !drawerShown"
      >
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-title v-if="!mobile"> Deal Detector </v-app-bar-title>
      <v-spacer></v-spacer>
      <p v-if="store.loggedInUser && !mobile">{{ store.loggedInUser.email }}</p>
      <v-btn v-if="store.loggedInUser" icon>
        <v-icon>mdi-account-circle</v-icon>
        <v-menu activator="parent">
          <v-list>
            <v-list-item value="logout" title="Ausloggen" @click="onLogout">
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawerShown" temporary>
      <v-list>
        <v-list-item
          v-for="path in paths"
          :key="path.title"
          :value="path"
          :to="path.path"
          link
        >
          <template v-if="path.icon" v-slot:prepend>
            <v-icon :icon="path.icon"></v-icon>
          </template>

          <v-list-item-title>{{ path.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
    <v-snackbar bottom right timeout="-1" v-model="_updateNotificationShown">
      An update is available
      <template v-slot:actions>
        <v-btn variant="tonal" @click="ServiceWorkerUpdateService.refreshApp">
          Update
        </v-btn>
        <v-btn icon="mdi-close" @click="updateNotificationShown = false" />
      </template>
    </v-snackbar>
    <v-dialog
      v-model="notificationDialogShown"
      v-if="notification?.title || notification?.body"
      width="500"
    >
      <v-card :title="notification?.title">
        <v-card-text v-if="notification?.body">
          {{ notification?.body }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="notificationDialogShown = false">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  overflow: hidden;
}

.v-main {
  height: 100%;
  overflow: hidden;
}

@media (display-mode: standalone) {
  .detail-content-with-toolbar #main-app-bar {
    display: none !important;
  }
  .detail-content-with-toolbar .v-main {
    --v-layout-top: 0px !important;
  }
}
</style>
