// Store the SW registration, so we can send it a message
// We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
// To alert the user there is an update they need to refresh for
import { Store } from "@/store";

const updateAvailable = (event: CustomEventInit<ServiceWorkerRegistration>) => {
  Store.serviceWorkerState.registration = event.detail;
  Store.serviceWorkerState.updateExists = true;
};

const setupUpdateListeners = () => {
  // Listen for our custom event from the SW registration
  document.addEventListener("swUpdated", updateAvailable, { once: true });

  // Prevent multiple refreshes
  navigator.serviceWorker.addEventListener("controllerchange", () => {
    if (Store.serviceWorkerState.refreshing) return;
    Store.serviceWorkerState.refreshing = true;
    // Here the actual reload of the page occurs
    window.location.reload();
  });
};

// Called when the user accepts the update
const refreshApp = () => {
  Store.serviceWorkerState.updateExists = false;
  // Make sure we only send a 'skip waiting' message if the SW is waiting
  if (
    !Store.serviceWorkerState.registration ||
    !Store.serviceWorkerState.registration.waiting
  )
    return;
  // send message to SW to skip the waiting and activate the new SW
  Store.serviceWorkerState.registration.waiting.postMessage({
    type: "SKIP_WAITING",
  });
};

export const ServiceWorkerUpdateService = {
  updateAvailable,
  setupUpdateListeners,
  refreshApp,
};
